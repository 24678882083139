/* Table.module.css */
.table {
  display: block;
  width: 100%;
  font-family: Arial, sans-serif;
}



.thead {
  display: block;
  position: sticky;
  top: 0; /* Makes the header sticky at the top */
}

.tbody {
  display: block;
  max-height:   1250px; /* Scrollable tbody */
  overflow-y: auto;
  overflow-x: auto; /* Allows horizontal scrolling */
}

.tr {
  display: flex;
  margin-bottom: 5px;
  align-items: center;
  border-bottom: solid rgb(218, 216, 216) 1px;
}

.th {
  flex: 1;
  padding: 7px 5px;
  text-align: center;
  min-width: 80px;
  white-space: nowrap;
}
.td {
  flex: 1;
  padding: 7px;
  text-align: center;
  min-width: 80px;
  white-space: nowrap;
}
.th {
  color: #666; /* Header color */
  cursor: pointer; /* Adds a pointer cursor on hover */
}

.tbody .tr:hover {
  background-color: #f9f9f9; /* Hover color for table rows */
}

.td {
  color: #666; /* Text color for data cells */
}
.pozitif {
  color: green !important;
}
.negatif {
  color: red !important;
}
.mobile_hidden{
  display: inline-block;
}
.mobile_show {
  display: none;
  }
@media screen and (max-width:600px)  {
  .th {
   
    min-width: unset;
   
  }
  .td {
   
    min-width: unset;
    max-width: 90px;
  }
  .mobile_show {
  display: inline-block;
  }
  .mobile_hidden {
    display: none;
    }
}