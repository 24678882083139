.app-container {
  font-size: 12px;
}
.d-flex-desktop {
  display: flex;
}
.w-50 {
  min-width: 50px;
}
.d-inline-block {
  display: inline-block;
}
@media screen and (max-width: 600px) {
  .mobile-hidden {
    display: none;
  }
  .d-flex-desktop {
    display: block;
  }
}
