

.withdraw-container {
  display: flex;
  flex-direction: column;
  gap:52px;
}
.message {
  padding: 10px;
  position: fixed;
  bottom: 10px;
  right: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  color: white;
}

.message.success {
  background-color: #4caf50; /* Başarı mesajı yeşil */
}

.message.error {
  background-color: #f44336; /* Hata mesajı kırmızı */
}


.withdraw-top{
  display: flex;
  justify-content: space-between;
  padding-left: 62px;
  gap:32px;

}
.withdraw-left{
  display: flex;
  flex-direction: column;
  gap:16px;
}
.withdraw-left-top{
  display: flex;
  align-items: center;
}
.wallet-back-btn {
  display: flex;
  align-items: center;
  border: none;
  background: none;
  min-width: 104px;
  justify-content: space-between;
  cursor: pointer;
}
.wallet-back-btn span {
  border-radius: 25px;
  background: var(--green-darker, rgba(143, 166, 61, 0.44));
  width: 12px;
  height: 12px;
  padding: 10px;
}
.wallet-selectDiv{
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.withdraw-left input {
  border: none;
  border-bottom: 1px solid
    var(--Components-Input-Outlined, rgba(145, 158, 171, 0.32));
  height: 14px;
  width: 450px;
  padding: 14px 16px;
}
.wallet-inputDiv label {
  display: block;
  margin-top: 20px;
}
.wallet-contunio-btn {
  margin-top: 32px;
  min-width: 480px;
  height: 44px;
  padding: 3px 78px;
  border-radius: 4px;
  background: #a7c449;

  border: none;
  color: white;
}
.wallet-contunio-btn:hover {
  background: #a7c449;
  background: #8fa63d;
}
@media screen and (max-width: 600px) {
  .withdraw-container{
    gap:20px;
    height: 100vh;
    padding: 20px 10px 10px 20px;
  }
  
.withdraw-top{
  display: block;
}
.wallet-contunio-btn {
  margin-top: 32px;
  min-width: unset;
  width: 300px;
  margin: auto;
}
.withdraw-top{
  padding-left: 0;
}
.wallet-selectDiv{
  width: 90%;
  margin: auto;
}
.withdraw-right{
  display: none;
}
.wallet-inputDiv {
  text-align: center;
}
}