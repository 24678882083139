.deposit-container {
  display: flex;
  flex-direction: column;
  gap:52px;
}

.deposit-top {
  display: flex;
  padding-left: 62px;
  gap: 32px;
}
.deposit-left-top{
  display: flex;
  align-items: center;
}
.wallet-back-btn {
  display: flex;
  align-items: center;
  border: none;
  background: none;
  min-width: 104px;
  justify-content: space-between;
  cursor: pointer;
}
.wallet-back-btn span {
  border-radius: 25px;
  background: var(--green-darker, rgba(143, 166, 61, 0.44));
  width: 12px;
  height: 12px;
  padding: 10px;
}


.deposit-left input {
  border: none;
  border-bottom: 1px solid
    var(--Components-Input-Outlined, rgba(145, 158, 171, 0.32));
  height: 14px;
  width: 450px;
  padding: 14px 16px;
}
.wallet-inputDiv label {
  display: block;
  margin-top: 20px;
}
.wallet-contunio-btn {
  margin-top: 32px;
  min-width: 480px;
  height: 44px;
  padding: 3px 78px;
  border-radius: 4px;
  background: #a7c449;

  border: none;
  color: white;
}
.wallet-contunio-btn:hover {
  background: #a7c449;
  background: #8fa63d;
}

@media screen and (max-width: 600px) {
  .deposit-container{
    gap:20px;
    height: 100vh;
    padding: 20px 10px 10px 20px;
  }
.deposit-top{
  display: block;
}
.wallet-contunio-btn {
  margin-top: 32px;
  min-width: unset;
  width: 300px;
  margin: auto;
}
.deposit-top{
  padding-left: 0;
}
.wallet-selectDiv{
  width: 90%;
  margin: auto;
}
.deposit-right{
  display: none;
}
.deposit-left input {
  width: 200px;
}
.wallet-inputDiv label {
  width: 100%;
  text-align: center;
}
}