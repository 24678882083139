#jupiter-terminal .text-white\/30 {
  color: white !important;
}
@media screen and (max-width: 600px) {
 
  #jupiter-terminal-instance .bottom-6  {
    bottom: 2.8rem !important;
  }
  #jupiter-terminal-instance .max-h-\[75vh\] {
    max-height: 80vh !important;
  }
}
