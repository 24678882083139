.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;

  position: sticky;
  top: 0;
  z-index: 1000;
}
.header-nav-link{
  cursor: pointer;
}
.header-left {
  display: flex;
  gap: 16px;
  align-items: center;
  padding: 2px;
}
.header-choose-exchange {
  cursor: pointer;
}
.header-right {
  display: flex;
}

.icon-link {
  color: #212b36;
  text-decoration: none;
  margin-right: 20px;
}

.icon {
  font-size: 24px;
}

.header-nav-link {
  color: #212b36;
  text-decoration: none;
  margin-right: 20px;
  display: flex;
  align-items: center;
  padding: 12px 6px;
}

.nav-link,
.nav-link a {
  user-select: none; /* Kullanıcının metin seçimini engeller */
  cursor: pointer; /* İmleci pointer olarak ayarlar */
}

.nav-link:hover {
  text-decoration: underline;
}

.dark-mode {
  background-color: #1a1a1a; /* Gece modunda arka plan rengi */
  color: #ffffff; /* Gece modunda metin rengi */
}

.light-btn {
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  transition: transform 0.3s ease; /* Transform özelliğine geçiş efekti ekleme */
}

.marka-icon {
  max-height: 50px;
  max-width: 200px;
}
/* Dark Mode */
.dark-mode {
  background-color: #1a1a1a; /* Gece modunda arka plan rengi */
  color: #ffffff; /* Gece modunda metin rengi */
}

.dark-mode .icon-link,
.dark-mode .nav-link {
  color: #ffffff; /* Gece modunda ikon ve navigasyon linkleri rengi */
}

/* Light Button in Dark Mode */
.light-btn.dark-mode {
  transform: rotate(180deg); /* Gece modunda butonun dönme animasyonu */
}

/* Dropdown menü stil ayarları */
.header-exchange-dropdown {
  position: absolute;
  top: 100%; /* Başlık çubuğunun hemen altında başlar */
  left: 75px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 200px; /* Dropdown genişliği */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1000; /* Diğer elementlerin üzerinde görünmesi için */
}

.header-exchange-dropdown div {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s;
}

.header-exchange-dropdown div:hover {
  background-color: #f1f1f1;
}

.header-exchange-dropdown img {
  width: 100px; /* İcon genişliği */
  height: 100px; /* İcon yüksekliği */
  margin-right: 10px; /* İcon ile metin arasındaki boşluk */
}
@media screen and (max-width:600px)  {
  .marka-icon {
    max-width: 120px;
  }
  .header-nav-link {
    margin-right: 0;
  }
}