.profile {
  padding-top: 10px;
  display: flex;
  gap:20px;
}

.profile__sidebar {
  width: 300px;
  min-height: 500px;
  max-height: 500px;
  border-radius: 8px;
  background: var(--bk-light, #fff);
  box-shadow: 0 4px 32px rgba(0, 0, 0, 0.12);
  padding: 10px;
}

.profile__header {
  border-radius: 12px;
  background: rgba(145, 158, 171, 0.08);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 20px;
  margin-bottom: 50px;
}

.profile__logo {
  max-width: 44px;
}

.profile__dropdown {
  position: relative;
}

.profile__dropdown-toggle {
  border-radius: 8px;
  background: #8fa63d;
  cursor: pointer;
  width: 100%;
  min-height: 56px;
  border: none;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}

.profile__dropdown-menu {
  border-radius: 4px;
  padding: 5px 0;
  width: 100%;
}

.profile__dropdown-menu li {
  list-style-type: none;
  padding: 0 12px 0 16px;
  align-items: center;
  cursor: pointer;
  min-height: 50px;
  display: flex;
  gap: 16px;
}

.profile__dropdown-menu li:hover {
  background-color: #f0f0f0;
}

.profile__main {
  width: 100%;
  min-height: 450px;
  padding-right: 10px;
}
@media screen and (max-width: 600px) {
  .profile__sidebar{
  min-height: unset;
  max-height: unset;
  margin: auto;
}
.profile {
  display: unset;
}
.profile__header {
  padding: 15px 10px;
  margin-bottom: 0;
}
.profile__main {
  width: 85%;
  min-height: unset;
  padding: 10px;
  margin: auto;
}
}
