
.trading__filters {
    display: flex;
    justify-content: space-between;
    color: #637381;
    padding: 5px;
    margin-top: 5px;
  }
  
  .trading__filters-left,
  .trading__filters-right {
    display: flex;
    align-items: center;
    gap: 10px;
  }