.userinfo-header-container {
    display: flex;
    align-items: center;
    min-width: 500px;
    width: 80%;
    font-size: 16px;
    padding: 15px 0;
  }

  .userinfo-header-icon {
    margin: 0 20px;
    align-items: center;
    display: flex;
  }

  .userinfo-header-subtitle {
    color: #70859b;
    font-size: 14px;
  }
@media screen and (max-width: 600px) {
  .userinfo-header-container {
    min-width: unset;
  }
}