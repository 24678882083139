.custom-input {
  width: 100%;

  }
  .custom-input input {
    border-radius: 8px;
    border: 1px solid var(--Components-Input-Outlined, rgba(145, 158, 171, 0.32));
    width: 100%;
    height: 44px;
    padding: 0;
  }
  
  .custom-input-label {
    display: block;
    position: absolute;
    z-index: 3;
    margin-top: -10px;
    font-size: 12px;
    color: #919eab;
    margin-left: 5px;
    padding: 0 10px;
    background-color: white;
  }
  @media screen and (max-width: 600px) {

  .custom-input input {
    padding: 0 5px;
    margin-bottom: 10px;
    max-width: 90%;
  }
  .custom-input {
    text-align: center;
  }
}