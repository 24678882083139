
.trading__header {
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.1);
  padding: 5px;
  margin-bottom: 5px;
}
.pozitif {
  color: green !important;
}
.negatif {
  color: red !important;
}
.trading__header_left {
  gap: 16px;
  margin-left: 5px;
}
.trading__header_left div {
    align-items: center;
   text-align: center;
   height: 40px;
  }
.trading__header_left,
.trading__header_right {
  display: flex;
  align-items: center;
}
.trading__header_right {
    gap: 10px;
    margin-right: 5px;
  
  }
.trading__header_info {
  display: flex;
  align-items: center;
  gap: 6px;
}
.trading__header_info img {
  width: 40px;
  height: 40px;
}
.trading__column {
  display: flex;
  flex-direction: column;
}
.trading__togle_menu{
    display: none;
  }
  .popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .mobile_hidden {
    display: flex;
  }

  @media (max-width: 1240px) {

    .trading__togle_menu{
      display: block;
    }
    
  }
  @media (max-width: 600px) {
    .mobile_hidden {
      display: none;
    }
  }
  