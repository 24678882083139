/* Table.module.css */
.table {
  display: block;
  width: 100%;
  font-family: Arial, sans-serif;
  min-height: 400px;
}

.thead {
  display: block;
  position: sticky;
  top: 0; /* Makes the header sticky at the top */
}

.tbody {
  display: block;
  max-height: 800px; /* Scrollable tbody */
  overflow-y: auto;
  overflow-x: auto; /* Allows horizontal scrolling */
}

.tr {
  display: flex;
  align-items: center;
}

.th,
.td {
  flex: 1;
  padding: 7px 10px;
  text-align: center;
  min-width: 80px;
  white-space: nowrap;
  cursor: pointer; /* Adds a pointer cursor on hover */
}

.th {
  color: #666; /* Header color */
  cursor: pointer; /* Adds a pointer cursor on hover */
}

.tbody .tr:hover {
  background-color: #f9f9f9; /* Hover color for table rows */
}

.td {
  color: #666; /* Text color for data cells */
}
.pozitif {
  color: green !important;
}
.negatif {
  color: red !important;
}
.mobile_show {
  display: none;
}
@media (max-width: 600px) {
  
  .mobile_show {
    display: block;
  }
}
