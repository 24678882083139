@keyframes slidenotification {
  from {
    right: -50px;
  }
  to {
    right: 20px;
  }
}

.notification-popup {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 420px;
  height: 600px;
  padding: 4px;
  margin-left: -50px;
  top: 100px;
  gap: 32px;
  right: 20px;
  border-radius: 16px;
  background: var(--bk-light, #fff);
  box-shadow: 0px 0px 20px 0px rgba(173, 173, 173, 0.24);
  animation: slidenotification 0.5s ease-out forwards;
}

.notification-popup-title {
  color: #292d32;
  text-align: center;
  font-size: 15px;
  font-weight: 600;
  padding: 20px 10px 20px;
}

.notification-container {
  padding: 10px;
  display: flex;
  align-items: center;
}

.notification-icon {
  padding: 10px;
}

.notification-date {
  color: var(--TEXT-3, #919eab);
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  margin: 0;
}

.notification-title p {
  margin: 5px;
}

/* Mobil cihazlar için medya sorgusu */
@media (max-width: 600px) {
  .notification-popup {
    width: 420px;
    height: 510px;
    top: 55px;
  }
  .notification-popup {
    width: calc(
      100% - 40px
    ); /* Ekranın %100'ü eksi 40px (20px sağdan ve 20px soldan boşluk) */
    right: 20px;
    left: 20px; /* Sol hizalama ekleniyor */
    margin-left: 0; /* Negatif marj kaldırılıyor */
  }
}
