.load-app {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 250px; /* Use padding for consistent centering */
  height: 100vh; /* Ensure full height to center vertically */
  width: 100%; /* Ensure full width for horizontal centering */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

.load-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  max-width: 337px; /* Maximum width for larger screens */
  height: 300px;
  padding: 60px 31px;
  flex-shrink: 0;
  border-radius: 22px;
  background: var(--green, #8fa63d);
  box-sizing: border-box;
}

.loading-span {
  color: var(--bk-light, #fff);
  text-align: center;
  font-family: Inter, sans-serif; /* Include fallback font */
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 160% */
  text-transform: capitalize;
}

.loading-indicator {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-arrow {
  border: 5px solid #f3f3f3; /* Outer circle of the arrow */
  border-top: 5px solid #3498db; /* Top colored part (rotating section) */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite; /* Animation */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Media Query for Mobile Devices */
@media (max-width: 480px) {
  .load-app {
    padding: 0; /* Remove extra padding for mobile */
    height: 100vh; /* Full screen height for centering */
  }

  .load-container {
    width: 90%; /* Full width minus some margin */
    padding: 40px 20px; /* Smaller padding for mobile */
  }

  .loading-span {
    font-size: 16px; /* Smaller font size for mobile */
    line-height: 24px; /* Adjust line height */
  }

  .loading-arrow {
    width: 40px; /* Smaller size for mobile */
    height: 40px;
  }
}
