.trading__trade {
  flex: 1;
  padding-top: 5px;
}
.trading__form {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 5px;
}

.trading__form label {
  display: flex;
}

.trading__form label span {
  flex: 1;
  border-bottom: 2px solid #ccc;
  padding: 5px;
}

.trading__form input {
  border: none;
  border-bottom: 2px solid #ccc;
  outline: none;
  padding: 5px;
  text-align: right;
  flex: 1;
  max-width: 70px;
}

.trading__form input::placeholder {
  text-align: right;
  color: #aaa;
}

.trading__form-buy,
.trading__form-sell {
  border: none;
  padding: 5px;
  border-radius: 10px;
  color: white;
  cursor: pointer;
}
.trading__form-buy {
  background-color: #8fa63d;
}

.trading__form-sell {
  background-color: #d24c00;
}
.trading__balance {
  display: flex;
  justify-content: space-around;
}

.balance__total,
.balance__available {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.sidebar{
    display: flex;
    justify-content: space-between;
    gap:5px;
    margin: 10px 0;
}
.sidebar__button{
    width: 100%;
    font-size: 12px;
    border: none;
    padding: 3px;
    border-radius: 10px;
    cursor: pointer;
}
@media screen and (max-width:600px)  {
  .balance__transfer{
    text-align: center;
    margin-top: 10px;
  }
  .trading__form {
    gap: 25px;
  }
}
