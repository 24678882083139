.keyrecord-container {
  display: flex;
  justify-content: space-around;
  gap: 16px;
  margin-bottom: 16px;
}
.keyrecord-title-exchange {
  width: 30px ;
  margin: 10px 30px 0;
}

.keyrecord-saved-button {
  border: none;
  border-radius: 4px;
  background: var(--green, #8fa63d);
  color: white;
  cursor: pointer;
  width: 200px;
}
@media screen and (max-width: 600px) {
  .keyrecord-container {
    display: unset;
  }
  .keyrecord-container div h3{
    margin: 20px auto;
}
.keyrecord-saved-button {
  width: 40%;
  min-height: 30px;
}
}
