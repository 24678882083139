.tokens-info-container {
    border-radius: 8px;
    background: var(--bk-light, #fff);
    box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.12);
    padding: 10px;
    min-height: 140px;
  }
  

  .search-and-actions{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .search-and-actions span{
    cursor: pointer;
  }
  .tokens-search-input {
   
    padding: 8px 10px;
    border: 2px solid #ccc; /* Gri tonunda bir kenarlık */
    border-radius: 8px; /* Köşeleri yuvarlak */
    outline: none; /* Odağı kaldırdığımızda dış çizgiyi kaldırır */
    margin: 10px 0; /* Üst ve alttan boşluk */
    font-size: 16px; /* Okunabilir bir font boyutu */
    transition: border-color 0.3s; /* Kenarlık rengi değişimini yumuşatır */
}

.tokens-search-input:focus {
    border-color: #3681d1; /* Fokuslandığında kenarlık rengini değiştir */
    box-shadow: 0 0 8px rgba(75, 140, 209, 0.2); /* Fokuslandığında hafif bir gölge */
}
.tokens-info-acitons{
  display: flex;
  gap:10px
}
@media screen and (max-width: 600px) {

.search-and-actions{
 display: block;
}
}