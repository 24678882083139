.footer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #fff;

  position: sticky;
  bottom: 0;
  z-index: 1000;
}

.footer-left {
  display: flex;
  gap: 16px;
  align-items: center;
  padding: 16px;
}
.footer-choose-exchange {
  cursor: pointer;
}
.footer-right {
  display: flex;
}
.footer-nav-link {
  color: #212b36;
  text-decoration: none;
  margin-right: 20px;
  display: flex;
  align-items: center;
  padding: 12px 6px;
}
/* Dropdown menü stil ayarları */
.footer-exchange-dropdown {
  position: absolute;
  top: 100%; /* Başlık çubuğunun hemen altında başlar */
  left: 75px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 200px; /* Dropdown genişliği */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1000; /* Diğer elementlerin üzerinde görünmesi için */
}

.footer-exchange-dropdown div {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s;
}

.footer-exchange-dropdown div:hover {
  background-color: #f1f1f1;
}

.footer-exchange-dropdown img {
  width: 100px; /* İcon genişliği */
  height: 100px; /* İcon yüksekliği */
  margin-right: 10px; /* İcon ile metin arasındaki boşluk */
}
.mobile-show{
  display: none;
}
@media screen and (max-width:600px)  {
  .footer-nav-link {
    margin-right: 0;
  }
  .mobile-show{
    display: flex;
  }
}