/* StepIndicator.module.css */
.stepIndicatorMainContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 11px;
}

.stepProgressWrapper {
    padding: 12px;
    background: #F7F7F7;
    border-radius: 59px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.stepIconBg {
    width: 40px;
    height: 40px;
    position: relative;
    background: white;
    border-radius: 30px;
}

.progressCircle {
    position: absolute;
    border-radius: 30px;
}

.progressLayerPrimary {
    width: 32px;
    height: 32px;
    left: 4px;
    top: 4px;
    background: #F9FAF7;
}

.progressLayerSecondary {
    width: 24px;
    height: 24px;
    left: 8px;
    top: 8px;
    background: rgba(143, 166, 61, 0.11);
}

.progressLayerTertiary {
    width: 10px;
    height: 10px;
    left: 15px;
    top: 15px;
    background: #8FA63D;
}

.stepTextActive {
    color: #8FA63D;
}

.progressGray {
    background: rgba(224, 224, 224, 0.68);
}

.progressDarkGray {
    background: #DADADA;
}

.progressBlack {
    background: #292D32;
}

.stepDottedLine {
    width: 12px;
    height: 0;
    transform: rotate(90deg);
    transform-origin: 0 0;
    border: 1px black dotted;
}

.stepDescriptionWrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

@media screen and (max-width:600px)  {
    .stepProgressWrapper {
       padding: 0;
}
}