/* Table.module.css */
.table {
    display: block;
    width: 100%;
    font-family: Arial, sans-serif;
    margin-bottom: 5px;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
  }
  
  .shadow {
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
  }
  
  .thead {
    display: block;
    top: 0; /* Makes the header sticky at the top */
  
  }
  
  .tbody {
    display: block;
    max-height: 800px; /* Scrollable tbody */
    overflow-y: auto;
    overflow-x: auto; /* Allows horizontal scrolling */
  }
  
  .tr {
    display: flex;
    align-items: center;
  
  }
  .tr:hover{
   
    cursor: pointer;
  }
  .th{
    flex: 1;
    padding: 7px 10px;
    text-align: center;
    min-width: 80px;
    white-space: nowrap;
  }
  .td {
    flex: 1;

    text-align: center;
    min-width: 80px;
    white-space: nowrap;
  }
  .th {
    color: #666; /* Header color */
    cursor: pointer; /* Adds a pointer cursor on hover */
  }
  
  .tbody .tr:hover {
    background-color: #f9f9f9; /* Hover color for table rows */
  }
  
  .td {
    color: #666; /* Text color for data cells */
  }
  .pozitif{
    color: green !important;
  }
  .negatif{
    color: red !important;
  }
  .mobile_hidden {
    display: unset;
  }
  @media screen and (max-width:600px)  {
  .mobile_hidden {
    display: none;
  }
  .th {
    padding-right: 0;
  }

  .table {
    height: 400px;
  }
}