.custom-select {
  position: relative;
  width: 100%;
}

.custom-select-element {
  border-radius: 8px;
  border: 1px solid var(--Components-Select-Outlined, rgba(145, 158, 171, 0.32));
  height: 44px;
  width: 100%;
  background-color: white;
}

.custom-select-label {
  display: block;
  position: absolute;
  z-index: 3;
  margin-top: -10px;
  font-size: 12px;
  color: #919eab;
  margin-left: 5px;
  padding: 0 10px;
  background-color: white;
}
