.radio-button {
  display: flex;
  align-items: center; /* Merkezi hizalamak için */
  cursor: pointer;

  font-size: 16px; /* Font boyutunu azalt */
  user-select: none;
  margin: 5px 0; /* Düzenli aralık */
}

.radio-input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  z-index: 1; /* İnput üzerinde tıklama imkanı sağlar */
}

.custom-radio {
  height: 16px; /* Yükseklik küçültüldü */
  width: 16px;  /* Genişlik küçültüldü */
  background-color: #ccc;
  border-radius: 50%;
  transition: background-color 0.2s, box-shadow 0.2s;
  margin-right: 10px; /* Metin ile arasına mesafe */
}

.radio-input:checked + .custom-radio {
  background-color: #2196F3;
  box-shadow: 0 0 0 5px #bbdefb; /* Harici gölge boyutunu azaltın */
}

.custom-radio::after {
  content: "";
  position: absolute;
  display: none;
  top: 4px; /* Konum küçültüldü */
  left: 4px; /* Konum küçültüldü */
  width: 8px; /* Boyut küçültüldü */
  height: 8px; /* Boyut küçültüldü */
  border-radius: 50%;
  background: white;
}

.radio-input:checked + .custom-radio:after {
  display: block;
}
@media screen and (max-width: 600px) {
  .radio-button {
    font-size: 11px; /* Font boyutunu azalt */
  }

  .custom-radio {
    margin-right: 5px; /* Metin ile arasına mesafe */
  }
}
