/* Trading.css */
.trading {
  display: flex;
  flex-direction: column;
}

.trading__header {
  display: flex;
  justify-content: space-between;
}

.trading__top,
.trading__bottom {
  display: flex;
  justify-content: space-between;
  gap: 5px;
  padding: 5px;
}

.trading_history-table {
  margin-top: 10px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
}

.trading__left {
  display: flex;
  flex-direction: column;
  border-radius: 16px;
}

.trading__left {
  flex: 2.5;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
  z-index: 999;
  background: white;
}

.trading_big_right {
  overflow: scroll;
  width: 100%;
}

.trading_big_right_upper_content {
  display: flex;
  margin-bottom: 10px;
}

.trading__middle {
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  background: var(--bk-light, #fff);
}

.trading__middle {
  flex: 5;
}

.trading__right {
  flex: 2.5;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.jupiter-terminals{
  background-color: black;
  min-height: 550px;
}
.line-container {
  display: flex;
  justify-content: center;
}

.line {
  display: flex;
  flex-direction: column;
  width: 98%;
  background-color: #ccc;
  height: 2px;
}

.trading__lastPrice {
  display: flex;
  width: 98%;
  align-items: center;
  justify-content: center;
  padding: 2px;
}

.trading__actions {
  display: flex;
  padding: 5px;
  justify-content: space-around;
  gap: 10px;
}
.trading__actions > div {
  width: 100%;
}
.trading_history-label {
  display: flex;
  align-items: center;
  padding: 5px;
  gap: 10px;
}

.not-spot {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #8fa63d70;
}

.no-spot-img {
  height: 200px;
  width: 200px;
}

.no-spot-text {
  margin-bottom: 16px;
}

.d-flex {
  display: flex;
}

.trade-change_buttons {
  justify-content: center;
}
.mobile_show {
  display: none;
}

@media (max-width: 1240px) {
  .trading__left {
    position: absolute;
   
  }
  .mobil__Table-show{
    display: block;
  }
  .mobil__Table-hide{
    display: none;
  }
  .trading__togle_menu {
    display: block;
  }
}

@media (max-width: 600px) {
  .mobile_show {
    display: block;
  }
  .jupiter-terminals{
    background-color: transparent;
    
  }
  .mobil__Table-show{
    display: block;
  }
  .mobil__Table-hide{
    display: none;
  }
  .trading__left {
   
    width: 98%;
  }
  .mobile_show {
    width: 100px;
    height: 40px;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: bold;
    transition: background-color 0.3s ease;
    position: relative;
  }
  
  .mobile_show.buy-button {
    background-color: #2ecc71; /* Al button color */
    color: white;
    border-radius: 4px 0 0 4px;
  }
  
  .mobile_show.buy-button:hover {
    background-color: #27ae60; /* Hover color */
  }
  
  .mobile_show.sell-button {
    background-color: #e74c3c; /* Sat button color */
    color: white;
    border-radius: 0 4px 4px 0;
  }
  
  .mobile_show.sell-button:hover {
    background-color: #c0392b; /* Hover color */
  }
  
  .mobile_show + .mobile_show {
    margin-left: 0;
  }
  
  .mobile_show.sell-button::before {
    content: "";
    position: absolute;
    left: -20px;
    top: 0;
    width: 0;
    height: 0;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-right: 20px solid #e74c3c;
  }
  
  .trading {
    overflow: scroll;
  }

  .trade-change_buttons button {
    width: 50%;
  }
  .trading__actions {
    display: unset;
    padding: unset;
  }
  .trading__actions > div {
    display: none;
  }

  .trading__actions > div.active {
    display: block;
  }
}
