/* auth.css - Revised for clarity and simplicity */
.auth-container {
  display: flex;
  gap: 150px;
}

.auth-aside,
.auth-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
}
.auth-main {
  justify-content: center;
  text-align: center;
}
.auth-aside {
  width: 538px;
  padding-bottom: 250px;
  background: var(--green-light, #f6f8f4);
}

.aside-top,
.aside-middle {
  display: flex;
  justify-content: center;
  align-items: center;
}

.aside-middle {
  width: 150px;
  height: 150px;
  padding: 56px;
  border-radius: 24px;
  background: #eff2e6;
  box-shadow: 0px 0px 20px rgba(173, 173, 173, 0.24);
}

.middle-box {
  width: 128px;
  height: 128px;
  border-radius: 4.92px;
  background: #628138;
  box-shadow: 0px 0px 24.6px rgba(173, 173, 173, 0.24);
}

.auth-main {
  width: 578px;
  padding: 32px;
  align-items: flex-start;
}

.main-title {
  color: var(--green, #8fa63d);
  font-family: Inter;
  font-weight: 500;
}

.title-highlight {
  color: var(--bk-dark, #1f2229);
}

.main-description {
  color: var(--TEXT-2, #637381);
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
}

.email-field {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid
    var(--Components-Input-Outlined, rgba(145, 158, 171, 0.32));
}

.field-label {
  position: absolute;
  top: 9px; /* Adjust this value as needed */
  left: 1;
  margin-left: 14px; /* Align with the input padding */
  background-color: #fff; /* Match the background of your form or container */
  padding: 0 4px; /* Small padding to ensure the background covers well */
  color: var(--Text-Light-Disabled, var(--TEXT-3, #919eab));
  font-family: Gilroy;
  font-size: 12px;
  font-weight: 300;
  transform: translateY(-100%);
}

.field-input {
  flex: 1;
  color: var(--Text-Light-Primary, var(--TEXT-1, #212b36));
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 300;
  padding-top: 10px; /* Add padding to ensure text does not overlap the label */
  padding-bottom: 10px; /* Add padding to ensure text does not overlap the label */
}

.auth-button {
  height: 56px;
  padding: 3px 78px;
  justify-content: center;
  align-self: stretch;
  border-radius: 8px;
  background: var(--bk-dark, #1f2229);
  color: var(--bk-light, #fff);
  font-family: Inter;
  font-size: 15px;
  font-weight: 500;
}
/* Button Hover Effect */
.auth-button:hover {
    background-color: #3a3e3b; /* Slightly lighter or darker than the original */
    cursor: pointer; /* Changes the cursor to a pointer to indicate it's clickable */
  }
  
  /* Button Active (Click) Effect */
  .auth-button:active {
    background-color: #2a2e2b; /* Darker than the hover state to simulate a pressed button */
    transform: translateY(2px); /* Slightly shifts the button down to mimic a press */
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.2); /* Optional: Adds an inset shadow to deepen the effect */
  }
  
  @media screen and (max-width:600px)  {
    .auth-aside{
      display: none;
    }
    .auth-container{
      gap:unset;
    display: flex;
    align-items: center;
    height: 100vh;
    }
    
    .auth-main{
      width: unset;
      padding: 10px;
    }
    .auth-button{
      padding: 0;
    }
  }