.backup-list-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .backup-list-popup .popup-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    cursor: pointer;
  }
  
  .backup-list-popup .popup-content {
    position: relative;
    width: 90%;
    max-width: 500px;
    background-color: #fff;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1001;
    border-radius: 8px;
  }
  
  .backup-list-popup .popup-content h2 {
    color: #333;
    text-align: center;
  }
  
  .backup-list-popup .popup-content form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .backup-list-popup label {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    color: #666;
  }
  
  .backup-list-popup input[type="text"],
  .backup-list-popup input[type="checkbox"] {
    padding: 8px;
    margin-top: 4px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
  }
  
  .backup-list-popup input[type="checkbox"] {
    margin-top: 0;
    width: auto;
  }
  .popup-content .backup-item{
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .backup-list-popup button {
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 10px;
  }
  
  .backup-list-popup button[type="submit"] {
    background-color: #4CAF50;
    color: white;
  }
  
  .backup-list-popup button[type="button"] {
    background-color: #f44336;
    color: white;
  }
  
  .backup-list-popup button:hover {
    opacity: 0.9;
  }
  