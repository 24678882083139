.select-container {
  width: 100%;
}

.select-item, .select-row {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  cursor: pointer;
  padding: 5px;
  border-radius: 16px;
  background: var(--bk-light, #fff);
  box-shadow: 0 0 20px rgba(173, 173, 173, 0.24);
}

.select-icon, .select-img img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.select-row-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  gap: 16px;
  border-radius: 16px;
  background: var(--bk-light, #fff);
  box-shadow: 0 0 20px rgba(173, 173, 173, 0.24);
}

.select-info-head {
  display: flex;
  align-items: center;
  gap: 100px;
}

.select-info-head > span {
  cursor: pointer;
}

.select-search {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 10px;
  background: var(--bk-light, #fff);
  box-shadow: 0 0 20px rgba(173, 173, 173, 0.24);
}

.select-search > input {
  padding: 15px;
  border-radius: 16px;
  background: var(--bk-light, #fff);
  border: none;
  outline: none;
}

.select-info, .select-label {
  display: flex;
  flex-direction: column;
}
