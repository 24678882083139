/* Table.module.css */
.table {
    display: block;
    width: 100%;
    font-family: Arial, sans-serif;
  }
  
  .shadow {
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
  }
  
  .thead {
    display: block;
    position: sticky;
    top: 0; /* Makes the header sticky at the top */
  
  }
  
  .tbody {
    display: block;
    max-height: 800px; /* Scrollable tbody */
    overflow-y: auto;
    overflow-x: auto; /* Allows horizontal scrolling */
  }
  
  .tr {
    display: flex;
    align-items: center;
  
  }
  
  .th, .td {
    padding: 7px 0px;
    text-align: center;
   
    white-space: nowrap;
    flex: 1; /* Esneklik ayarı */
}

  
  .th {
    color: #666; /* Header color */
    cursor: pointer; /* Adds a pointer cursor on hover */
  }
  
  .tbody .tr:hover {
    background-color: #f9f9f9; /* Hover color for table rows */
  }
  
  .td {
    color: #666; /* Text color for data cells */
  }
  

  .einput {
    width: 100%; /* Tam genişlik kullan, ancak taşmamalı */
    padding: 5px; /* İç boşluk vererek içeriğin okunurluğunu artır */
    margin: 0; /* Dış boşluk sıfır */
    border: 1px solid #ccc; /* Kenarlık rengi */
    background-color: #fff; /* Beyaz arka plan */
    font-size: 16px; /* Font boyutu */
    font-family: Arial, sans-serif; /* Yazı tipi */
   
  }
  
  .einput:focus {
    border-color: #4A90E2; /* Fokus durumunda kenarlık rengi */
    outline: none; /* Varsayılan tarayıcı çerçevesini kaldır */
  }
  
.contextMenu {
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 2px 10px rgba(0,0,0,0.2);
  border-radius: 5px;
  overflow: hidden;
  cursor: pointer;
  z-index: 1000; /* Yüksek z-index, menünün diğer elementlerin üstünde görünmesini sağlar */
}

.contextMenuItem {
  padding: 8px 20px;
  font-size: 16px;
  color: #333;
  background-color: white;
  transition: background-color 0.2s, color 0.2s;
}

.contextMenuItem:hover {
  background-color: #0056b3; /* Mavi arka plan renk değişikliği */
  color: white;
}
@media screen and (max-width: 600px) {
  .th, .td {
    min-width: 85px;
}
 
.tr {
  width: 100%;
}
}
