.d-flex {
  display: flex;
  align-items: center;
}

.container__piyasalar {
  padding: 20px;
}
.container__head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  margin-top: 10px;
}

.piyasalar-filter {
  display: flex;
  gap: 16px;
}
.piyasalar-left {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.piyasalar-left-a {
  flex: 3;
}
.piyasalar-left-b {
  background-color: black;
  margin-top: 110px;
  max-height: 600px;
  flex: 1;
}
@media screen and (max-width: 600px) {
  .piyasalar-filter {
    display: flex;
    gap: 5px;
  }
  .piyasalar-left-b {
    display: none;
  }
  .piyasalar-left {
    font-size: 9px;

    width: 100%;
    flex: 6;
  }
  .container__piyasalar {
    padding: unset;
  }
}
