.tab-groups {
  border-radius: 44px;
  border: 1px solid #f5f5f5;
  background-color: #F5F5F5;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.03);
  padding: 3px 4px;
  display: flex;
  
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.tabs-button {
  padding: 3px;
  border-radius: 44px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.03);
  border: none;
  width: 100%;
 
  color: var(--TEXT-2, #637381);
  /* medium/14 */
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 157.143% */
  text-transform: capitalize;
  cursor: pointer;
}

.tabs-button:hover {
  background: var(--bk-light, #cacaca);
}

.tabs-button.active {
  background: var(--bk-light, #fff);
  color: var(--TEXT-1, #212b36);
}