/* Genel Stil */
.wallet-container {
  display: flex;
  justify-content: space-between;
  gap: 16px;
}

/* Sol Panel Stili */
.wallet-left {
  flex: 6;
  padding: 20px;
}
/* Sağ Panel Stili */
.wallet-right {
  flex: 3;
}

/* İkon Butonlar Stili */
.al-icon-btn,
.sat-icon-btn ,.fast-withdraw-btn,.fast-deposit-btn{
  border-radius: 13.974px;
  width: 25px;
  height: 25px;
  padding: 2px;
  cursor: pointer;
}

/* AL İkon Buton Stili */
.al-icon-btn {
  background: #8fa63d;
  border: none;
  margin-right: 5px;
}

/* AL İkon Buton Hover Stili */
.al-icon-btn:hover {
  opacity: 0.8;
  border: 1px solid #da5058;
}

/* SAT İkon Buton Stili */
.sat-icon-btn {
  background: #da5058;
  border: none;
}

/* SAT İkon Buton Hover Stili */
.sat-icon-btn:hover {
  opacity: 0.8;
  border: 1px solid #8fa63d;
}

.fast-deposit-btn {
  background: #eccc15;
  border: none;
  margin-right: 5px;
}

/* fast İkon Buton Hover Stili */
.fast-deposit-btn:hover {
  opacity: 0.8;
  border: 1px solid #507cda;
}
/* fast İkon Buton Stili */
.fast-withdraw-btn {
  background: #507cda;
  border: none;
  margin-left: 5px;
}

/* fast İkon Buton Hover Stili */
.fast-withdraw-btn:hover {
  opacity: 0.8;
  border: 1px solid #507cda;
}
.wallet-right {
  padding-top: 32px;
  display: flex;
  flex-direction: column;
  text-align: center;
} /* Sağ Panel İçerik Stili */
.wallet-right-contents {
  border-radius: 16px;

  background: var(--bk-light, #fff);
  /* x4 */
  box-shadow: 0px 0px 2px 0px rgba(145, 158, 171, 0.24),
    -20px 20px 40px -4px rgba(145, 158, 171, 0.24);
  padding: 10px;
}

/* Geçmiş Cüzdan Stili */
.history-wallet {
  border-radius: 16px;
  background: rgba(143, 166, 61, 0.2);
  box-shadow: 0px 0px 20px 0px rgba(173, 173, 173, 0.24);
  height: 62px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 20px 0;
}

/* Geçmiş Cüzdan Metin Stili */
.history-wallet span {
  margin-left: 10px;
  margin-bottom: -10px;
}
@media (max-width: 600px) {

.wallet-left h1{
  margin: 5px 0;
  display: inline-block;
}
.wallet-left input{
margin: 10px 0 10px 45px;
    border-radius: 10px;
    min-height: 20px;
    padding: 5px;
}
}
