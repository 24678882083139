.user-info-container {
    border-radius: 8px;
    background: var(--bk-light, #fff);
    box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.12);
    padding: 50px 10px 10px;
    min-height: 140px;
  }
@media screen and (max-width: 600px) {
  .user-info-container {
    padding: 5px 10px 40px;
    text-align: center;
  }
}

  